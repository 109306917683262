.how-conenct {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
    position: relative;
    z-index: 0;
}

.how-conenct__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 0 120px 100px;
}

.how-conenct__bg-shape-1 {
    width: 710px;
    height: 710px;

    position: absolute;
    top: -58px;
    left: -247px;
    z-index: 0;
}

.how-conenct__heading {
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content;

    align-items: end;
    column-gap: 15px;
    position: relative;
    z-index: 1;
}

.how-conenct__heading-title {
    font-family: 'Manrope';
    color: var(--text-color);
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 62px;
}

.how-conenct__heading-line {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 13px;
}

.how-conenct__heading-line-elem {
    margin: -9px 0 0;
    width: 100%;
    height: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFC700FF' stroke-width='1' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.how-conenct__heading-line-icon {
    width: 60px;
    height: 60px;
    margin-left: 20%;
}

.how-conenct__heading-line-icon-stroke {
    stroke: var(--accent-color);
}

.how-conenct__heading-controls {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.how-conenct__heading-control {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
}


.how-conenct__heading-control-icon {
    width: 60px;
    height: 60px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.how-conenct__heading-control-selected .how-conenct__heading-control-icon {
    opacity: 1;
}

.how-conenct__heading-control-icon-stroke {
    stroke: var(--accent-color);
}

.how-conenct__heading-control-btn {
    width: fit-content;
    box-sizing: border-box;
    padding: 20px 25px;
    color: var(--text-color);
    white-space: nowrap;

    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    border-radius: 12px;
    box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
    transition: all 0.2s ease-in-out;
    background-color: var(--background-color);

}

.how-conenct__heading-control-selected .how-conenct__heading-control-btn {
    background-color: var(--text-color);
    color: var(--background-color);
}

.how-conenct__cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 58px 0 0;
    position: relative;
    z-index: 1;
}

.how-conenct__cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 32px;

}

.how-conenct__card {
    max-width: 254px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.how-conenct__card-icon-box {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    border-radius: 50%;
    box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
}

.how-conenct__card-icon-box_last {
    background-color: var(--accent-color);
}

.how-conenct__card-icon {
    width: 90px;
    height: 90px;
}

.how-conenct__card-icon-stroke {
    stroke: var(--accent-color);
}

.how-conenct__card-icon-box_last .how-conenct__card-icon-stroke {
    stroke: var(--text-on-contrast-color);
}

.how-conenct__card-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: var(--text-color);
    margin: 16px 0 0;
}

.how-conenct__card-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 8px 0 0;
    text-align: center;
    color: var(--text-color);
}

.how-conenct__btn {
    display: flex;
    padding: 20px 50px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: fit-content;
    border-radius: 12px;
    margin: 70px auto 0;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.how-conenct__card-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media (max-width: 1280px) {
    .how-conenct__container {
        padding: 0 60px 100px;
    }
}

@media (max-width: 1100px) {
    .how-conenct__heading {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        column-gap: unset;
        gap: 20px;
        align-items: start;
    }

    .how-conenct__heading-line {
        display: none;
    }

    .how-conenct__heading {
        padding: 0 16px;
        box-sizing: border-box;
    }


    .how-conenct__container {
        padding: 0 0 40px;
    }

    .how-conenct__heading-controls {
        justify-content: unset;
    }



    .how-conenct__heading-control {
        gap: 6px;
    }

    .how-conenct__cards {
        padding: 0 16px;
        box-sizing: border-box;

    }

    .how-conenct__heading-control-btn {
        padding: 12px 17px;
    }

    .how-conenct__heading-title {
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 38px;
    }


}

@media (max-width: 880px) {

    .how-conenct__heading-title {
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px;
    }

    .how-conenct__cards {
        padding: 0 16px;
        box-sizing: border-box;
        grid-template-columns: repeat(2, minMax(0, 1fr));
        grid-auto-rows: max-content;
        row-gap: 32px;
        justify-content: center;
        justify-items: center;
    }

    .how-conenct__bg-shape-1 {
        width: 476px;
        height: 476px;
        top: -28px;
        left: -184px;
    }
}

@media (max-width: 600px) {
    .how-conenct__cards {
        padding: 0 16px;
        box-sizing: border-box;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        row-gap: 14px;
        justify-content: center;
        justify-items: center;
    }

    .how-conenct__cards-container{
        margin: 20px 0 0;
    }
    .how-conenct__card-icon-box {
        width: 116px;
        height: 116px;
    }

    .how-conenct__card-icon {
        width: 60px;
        height: 60px;
    }

    .how-conenct__card {
        width: 100%;
        max-width: unset;
        display: grid;
        grid-template-columns: 116px 1fr;
        grid-template-rows: max-content;
        column-gap: 14px;

    }

    .how-conenct__card-texts {
        align-items: flex-start;
    }

    .how-conenct__card-title {
        margin: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
    }

    .how-conenct__card-text {
        margin: 6px 0 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
    }

    .how-conenct__btn {
        width: calc(100% - 16px * 2);
        margin: 30px auto 0;
        padding: 15px 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
}