.faq {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 100px 120px 0;
}

.faq__title {
    color: var(--text-color);

    font-family: 'Manrope';
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    max-width: 1200px;
    width: 100%;
}


.faq__items {
    margin: 36px 0 0;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
}

.faq__item {
    padding: 24px 0 34px;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-template-rows: max-content;
    column-gap: 24px;

    /* border-radius: 16px; */
    transition: all 0.2s ease-in-out;
    border-top: 1px solid var(--border-color);
}



.faq__item-info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.faq__item-arrow {
    width: 24px;
    height: 24px;
    transition: all 0.4s ease-in-out;

}

.faq__item_selected .faq__item-arrow {
    transform: rotate(180deg);
}

.faq__item-arrow-stroke {
    opacity: 0.3;
    stroke: var(--text-color);
    transition: all 0.2s ease-in-out;

}

.faq__item_selected .faq__item-arrow-stroke {
    opacity: 1;
}

.faq__item-info-question {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.faq__item-info-answer-box {
    width: 100%;
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-template-rows: max-content;
    column-gap: 12px;

}

.faq__item-info-answer {
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 16px 0 0;
}

.faq__item-info-answer-link {
    text-decoration: underline;
}

.faq__item-info-answer-icon {
    width: 48px;
    height: 18px;
    margin: 16px 0 0;
}

@media (max-width: 880px) {
    .faq {
        padding: 40px 20px 0;
    }

    .faq__title {
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
    }

    .faq__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 12px 0 0;
    }

    .faq__items {
        margin: 28px 0 0;
    }

    .faq__item {
        padding: 16px 0 24px;
    }

    .faq__item-info-answer-box {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        row-gap: 8px;
    }

    .faq__item-info-question {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; 
    }

    .faq__item-info-answer {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
    }
}