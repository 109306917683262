.popup {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--popup-overlay-color);
  box-sizing: border-box;
  padding: 20px 8px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 100;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  width: 100%;
  max-width: 500px;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 60px;
  background-color: var(--background-color);
  box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
  box-sizing: border-box;
  padding: 50px 40px 40px;
}

.popup__form-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup__close-btn {
  width: 37px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  right: 24px;
}

.popup__close-icon {
  width: 33px;
  height: 32px;
}

.popup__close-icon-fill {
  fill: var(--control-btn-color);
}

.popup__title {
  max-width: 232px;
  font-size: 24px;
  font-weight: 800;
  line-height: 108%;
}

.popup__result-block .popup__title {
  max-width: 100%;
  padding-top: 28px;
}

.popup__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 125%;
  padding-top: 12px;
}

.popup__result-block .popup__text {
  text-align: center;
}

.popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}

.popup__form-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 18px 0;
  margin-top: 12px;
}

.popup__form-btn_disabled {
  pointer-events: none;
  user-select: none;
}

.popup__result-block .popup__form-btn {
  margin-top: 60px;
}

.popup__result-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__bg-circle {
  width: 404px;
  height: 404px;
  position: absolute;
  top: -186px;
  left: -106px;
  border-radius: 50%;
  opacity: 0.3;
  background: var(--accent-shape-bg-color);
}

.popup__img-box {
  width: 200px;
  height: 200px;
}

.popup__result-icon {
  width: 100%;
  height: 100%;
}

.popup__result-icon-stroke {
  stroke: var(--accent-color);
}

@media (max-width: 780px) {
  .popup {
    align-items: flex-start;
  }

  .popup__container {
    border-radius: 26px;
    padding: 44px 20px 20px;
  }

  .popup__close-btn {
    width: 35px;
    height: 32px;
    top: 16px;
    right: 16px;
  }

  .popup__title {
    font-size: 22px;
  }

  .popup__text {
    width: 88%;
    font-size: 14px;
  }

  .popup__form {
    width: 100%;
    margin-top: 20px;
  }
}
