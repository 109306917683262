.custom-input {
  width: 100%;
  display: grid;
  gap: 4px;
  position: relative;
  transition: opacity 0.3s ease-in;
}

.custom-input__label {
  transform-origin: top left;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
  transform: translate(16px, 15px) scale(1);
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  z-index: 2;
  pointer-events: none;
}

.custom-input_focused .custom-input__label {
  width: 100%;
  max-width: calc(133% - 32px);
  transform: translate(16px, 8px) scale(0.63);
  opacity: 0.6;
  pointer-events: auto;
  user-select: none;
}

.custom-input__input {
  width: 100%;
  position: relative;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  border-radius: 12px;
  background-color: var(--input-bg-color);
  box-sizing: border-box;
  padding: 22px 16px 8px 16px;
  z-index: 1;
}
