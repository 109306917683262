.switch {
  width: 100%;
  box-sizing: border-box;
}

.switch_type_form {
  border-radius: 12px;
  background: var(--input-bg-color);
  padding: 2px;
}

.switch__list {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
}

.switch_type_form .switch__list {
  gap: 2px;
}

.switch__item {
  width: 100%;
  display: flex;
}

.switch__select-btn {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 12px;
  background: var(--background-color);
  box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
  transition: all 0.2s ease-in-out;
}

.switch__select-btn_selected {
  background: var(--btn-selected-color);
}

.switch_type_form .switch__select-btn {
  height: 44px;
  border-radius: 8px;
  background: var(--input-bg-color);
  box-shadow: unset;
}

.switch_type_form .switch__select-btn_selected {
  background: var(--btn-selected-color);
}

.switch__btn-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 114%;
  transition: all 0.2s ease-in-out;
}

.switch_type_form .switch__btn-text {
  font-family: Manrope;
  font-size: 16px;
  line-height: 112.5%;
}

.switch__select-btn_selected .switch__btn-text {
  color: var(--text-on-contrast-color);
}

.switch__btn-icon {
  width: 26px;
  height: 26px;
}

.switch__btn-icon-stroke {
  stroke: var(--icon-primary-color);
  transition: all 0.2s ease-in-out;
}

.switch__select-btn_selected .switch__btn-icon-stroke {
  stroke: var(--accent-color);
}

@media (max-width: 780px) {
  .switch_type_form {
    border-radius: 10px;
  }

  .switch__list {
    gap: 6px;
  }

  .switch__select-btn {
    height: 38px;
    border-radius: 8px;
  }

  .switch_type_form .switch__select-btn {
    border-radius: 6px;
  }

  .switch__btn-text {
    font-size: 12px;
  }

  .switch_type_form .switch__btn-text {
    font-size: 12px;
    line-height: 133%;
  }

  .switch__btn-icon {
    width: 20px;
    height: 20px;
  }
}
