.key-points {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
    position: relative;
    z-index: 1;
}

.key-points__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 0 120px 100px;
}

.key-points__heading {
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content;
    column-gap: 80px;
}

.key-points__heading-title {
    font-family: 'Manrope';
    color: var(--text-color);
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 62px;
}

.key-points__heading-controls {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.key-points__heading-control {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
}


.key-points__heading-control-icon {
    width: 60px;
    height: 60px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.key-points__heading-control-selected .key-points__heading-control-icon {
    opacity: 1;
}

.key-points__heading-control-icon-stroke {
    stroke: var(--accent-color);
}

.key-points__heading-control-btn {
    width: fit-content;
    box-sizing: border-box;
    padding: 20px 25px;
    color: var(--text-color);
    white-space: nowrap;

    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    border-radius: 12px;
    box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
    transition: all 0.2s ease-in-out;
    background-color: var(--background-color);

}

.key-points__heading-control-selected .key-points__heading-control-btn {
    background-color: var(--text-color);
    color: var(--background-color);
}

.key-points__cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.key-points__cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 24px;
    align-items: center;
    margin: 60px 0 0;
}

.key-points__card {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 30px 50px;
    box-sizing: border-box;
    height: 320px;
    border-radius: 60px;
    background-color: var(--background-color);
    box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
}

.key-points__card_big {

    height: 360px;


}

.key-points__card-img {
    object-fit: cover;
    position: absolute;
    width: 160px;
    height: 160px;
    right: 16px;
    top: 16px;
    z-index: 1;
}

.key-points__card_big .key-points__card-img {
    width: 388px;
    height: 388px;
    right: -129px;
    top: -200px;
}


.key-points__card-bg-shape {
    position: absolute;
    z-index: 0;
    width: 392px;
    height: 392px;
}

.key-points__card-bg-shape_0 {
    top: -256px;
    right: -70px;
}

.key-points__card-bg-shape_1 {
    top: -157px;
    left: -153px;
}

.key-points__card-bg-shape_2 {
    bottom: -153px;
    right: -180px;
}

.key-points__card-index {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    position: absolute;
    top: 40px;
    left: 30px;
    color: var(--accent-color);
}

.key-points__card-title {
    white-space: pre-line;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: var(--text-color);
    margin-top: 142px;
    position: relative;
    z-index: 3;
}

.key-points__card_big .key-points__card-title {
    margin-top: 162px;
}

.key-points__card-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 16px 0 0;
    color: var(--text-color);
    position: relative;
    z-index: 3;
}

.key-points__like {
    margin: 26px auto 0;
    width: fit-content;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 12px;
    max-width: 100%;
}

.key-points__like-icon {
    width: 60px;
    height: 60px;
}

.key-points__like-icon-stroke {
    stroke: var(--accent-color);
}

.key-points__like-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: var(--text-color);
}

@media (max-width: 1280px) {
    .key-points__container {
        padding: 0 60px 100px;
    }
}

@media (max-width: 1100px) {
    .key-points__heading {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        column-gap: unset;
        gap: 20px;
    }

    .key-points__heading {
        padding: 0 16px;
        box-sizing: border-box;
    }

    .key-points__like {
        padding: 0 16px;
        box-sizing: border-box;
    }

    .key-points__container {
        padding: 0 0 40px;
    }

    .key-points__heading-controls {
        justify-content: unset;
    }

    .key-points__cards-container {
        padding: 0 16px;
        box-sizing: border-box;
    }

    .key-points__cards {
        margin: 20px 0 0;
    }

    .key-points__heading-control {
        gap: 6px;
    }

    .key-points__heading-control-icon {
        width: 50px;
        height: 50px;
    }

    .key-points__heading-control-btn {
        padding: 12px 17px;
    }

    .key-points__heading-title {
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 38px;
    }

    .key-points__card-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    .key-points__card-text {
        margin: 10px 0 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}

@media (max-width: 880px) {

    .key-points__cards-container {
        padding: 0 0 16px;
    }

    .key-points__card-slide {
        max-width: 300px;
        height: 290px !important;
    }

    .key-points__card {
        border-radius: 40px;
        max-width: 300px;
        height: 270px !important;
        padding: 0 24px 50px;
        box-shadow: 0px 6px 14px 0px var(--container-shadow-color);
    }

    .key-points__card_big {
        max-width: 300px;
        height: 270px !important;
    }

    .key-points__card-index {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        top: 30px;
        left: 24px;
    }

    .key-points__card-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-top: 108px !important;
    }

    .key-points__card-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 10px 0 0;
    }

    .key-points__card-img {
        width: 120px;
        height: 120px;
        right: 16px;
        top: 16px;
    }

    .key-points__card_big .key-points__card-img {
        width: 302px;
        height: 302px;
        top: -160px;
        right: -127px;
    }

    .key-points__card-bg-shape_0 {
        top: -283px;
        right: -109px;
    }

    .key-points__card-bg-shape_1 {
        top: -194px;
        left: -225px;
    }

    .key-points__card-bg-shape_2 {
        bottom: -152px;
        right: -234px;
    }

    .key-points__card:first-of-type {
        margin-left: 16px;
    }

    .key-points__card:last-of-type {
        margin-right: 16px;
    }

    .key-points__like {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-template-columns: unset;
        grid-template-rows: unset;
        column-gap: unset;
        gap: 6px;
    }

    .key-points__like-icon {
        width: 42px;
        height: 42px;
    }

    .key-points__like-text {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

}