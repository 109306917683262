.heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: var(--accent-color);
}

.heading__container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding: 60px 0 98px 120px;
}

.heading__content {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
}

.heading__logo {
  width: 154px;
  height: 130px;
}

.heading__logo-fill {
  fill: var(--text-on-contrast-color);
}

.heading__logo-bg {
  fill: var(--logo-bg-color);
}

.heading__title-box {
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 10px;
}

.heading__title {
  font-size: 64px;
  font-weight: 800;
  line-height: 100%;
}

.heading__map-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: -3px;
  left: 236px;
}

.heading__map-icon-fill {
  fill: var(--text-on-contrast-color);
}

.heading__map-icon-secondary-fill {
  fill: #ffc500;
}

.heading__text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 125%;
  padding-top: 20px;
}

.heading__btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 111%;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 30px;
  margin-top: 40px;
}

.heading__img-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 2;
}

.heading__img-box_type_top-left {
  width: 32.57%;
  right: 23.54%;
  top: -13.71%;
}

.heading__img-box_type_top-right {
  width: 34.79%;
  right: -16.11%;
  top: -28.29%;
}

.heading__img-box_type_bottom-right {
  width: 44.79%;
  right: -11.18%;
  top: 48.11%;
}

.heading__img-box_type_bottom-left {
  width: 16.38%;
  right: 35.14%;
  top: 82.43%;
}

.heading__img-box_type_center {
  width: 9.31%;
  right: 35.49%;
  top: 60.57%;
}

.heading__bg-circle {
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  background: var(--heading-shape-bg-color);
  filter: blur(52px);
  z-index: 0;
}

.heading__bg-circle_type_big {
  width: 56.53%;
  opacity: 0.5;
  background: var(--heading-shape-secondary-bg-color);
  filter: blur(112px);
  top: -2.14%;
  right: -17.08%;
}

.heading__bg-circle_type_top-left {
  width: 90.83%;
  bottom: -5.4%;
}

.heading__bg-circle_type_top-right {
  width: 85.03%;
  left: 1.8%;
  bottom: -4.13%;
}

.heading__bg-circle_type_bottom-right {
  width: 79.38%;
  opacity: 0.7;
  top: 6.29%;
}

.heading__bg-circle_type_bottom-left {
  width: 89.41%;
  opacity: 0.4;
  left: 8.47%;
  top: 8.37%;
}

.heading__bg-circle_type_center {
  width: 108.96%;
  left: -2.24%;
  top: -3.73%;
}

.heading__img {
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.heading__img-box_type_bottom-right .heading__img {
  transform: rotate(45deg);
}

.heading__line-circle {
  width: 18px;
  min-width: 14px;
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 50%;
  background: var(--background-color);
  z-index: 2;
}

.heading__img-box_type_top-left .heading__line-circle_type_small {
  left: 55.01%;
  top: 40.82%;
}

.heading__img-box_type_bottom-right .heading__line-circle_type_small {
  left: 52.24%;
  top: 35.88%;
}

.heading__line-circle_type_big {
  width: 6.4%;
  left: 39.23%;
  bottom: -1.73%;
}

.heading__line {
  object-fit: contain;
  position: absolute;
  z-index: 2;
}

.heading__line_type_long {
  /* width: 1172px; */
  width: 181.71%;
  right: 46.35%;
  top: 32.91%;
  z-index: 3;
}

.heading__line_type_short {
  /* width: 184px; */
  width: 39.23%;
  left: 41.36%;
  top: 95.03%;
  z-index: 1;
}

.heading__line_type_long-mobile,
.heading__line_type_short-mobile {
  display: none;
}

@media (max-width: 1250px) {
  .heading__container {
    padding: 32px 0 48px 32px;
  }

  .heading__title {
    font-size: 54px;
  }

  .heading__map-icon {
    width: 50px;
    height: 50px;
    bottom: 0;
    left: 196px;
  }

  .heading__text {
    font-size: 20px;
  }

  .heading__btn {
    margin-top: 32px;
  }
}

@media (max-width: 900px) {
  .heading__container {
    padding: 30px 20px;
  }

  .heading__content {
    max-width: 350px;
  }

  .heading__logo {
    width: 104px;
    height: 88px;
  }

  .heading__title-box {
    margin-top: 0;
  }

  .heading__title {
    font-size: 38px;
  }

  .heading__map-icon {
    width: 31px;
    height: 31px;
    left: 140px;
  }

  .heading__text {
    font-size: 18px;
    padding-top: 12px;
  }

  .heading__btn {
    font-size: 14px;
    border-radius: 10px;
    padding: 15px 30px;
    margin-top: 24px;
  }
}

@media (max-width: 600px) {
  .heading__container {
    padding: 124px 20px 30px;
  }

  .heading__btn {
    width: 100%;
  }

  .heading__img-box_type_top-left {
    width: 56.41%;
    left: -13.85%;
    top: -17.65%;
  }

  .heading__img-box_type_bottom-right {
    width: 80.88%;
    right: -26.55%;
    top: -4.59%;
  }

  .heading__img-box_type_bottom-left {
    width: 31.28%;
    right: -18.46%;
    top: 50.98%;
  }

  .heading__bg-circle_type_top-left {
    width: 90.91%;
    bottom: -4.48%;
    left: 5.15%;
    opacity: 0.6;
    filter: blur(37px);
  }

  .heading__bg-circle_type_bottom-right {
    width: 72.87%;
    left: 11.8%;
    top: 5.53%;
    opacity: 0.6;
    filter: blur(37px);
    transform: rotate(20.807deg);
  }

  .heading__img-box_type_bottom-right .heading__img {
    transform: rotate(65.807deg);
  }

  .heading__line-circle {
    width: 14px;
  }

  .heading__img-box_type_bottom-right .heading__line-circle_type_small {
    left: 54.25%;
    top: 38.22%;
  }

  .heading__line-circle_type_big {
    left: 75.91%;
    bottom: 6.54%;
  }

  .heading__line_type_long-mobile {
    display: block;
    width: 34.29%;
    left: 26.63%;
    top: 41.21%;
    z-index: 3;
  }

  .heading__line_type_short-mobile {
    display: block;
    width: 97.27%;
    left: 76.82%;
    bottom: -10.51%;
    z-index: 1;
  }

  .heading__img-box_type_center,
  .heading__img-box_type_top-right,
  .heading__bg-circle_type_big,
  .heading__bg-circle_type_top-right,
  .heading__bg-circle_type_bottom-left,
  .heading__bg-circle_type_center,
  .heading__img-box_type_top-left .heading__line-circle_type_small,
  .heading__line_type_long,
  .heading__line_type_short {
    display: none;
  }
}
