.banenr {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: var(--bnr-bg-color);
    height: 500px;
}

.banenr__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    /* padding: 60px 0 98px 120px; */
}

.banenr__contnent {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 640px;
    height: 640px;
    background-color: var(--bnr-bg-color);
    position: absolute;
    z-index: 3;
    left: 112px;
    top: -70px;
}

.banenr__icon {}

.banenr__icon_pc {
    width: 370px;
    height: 370px;
}

.banenr__icon_mobile {
    display: none;
    width: 184px;
    height: 170px;
}

.banenr__bg {
    width: 868px;
    height: 868px;
    position: absolute;
    z-index: 0;
    right: 0;
    top: -242px;
}


@media (max-width: 1250px) {
    .banenr__bg {
        right: -150px;
    }

    .banenr__contnent {
        left: 0px;
    }
}

@media (max-width: 880px) {
    .banenr {
        height: 400px;
    }

    .banenr__container {
        flex-direction: column;
        align-items: center;
    }

    .banenr__bg {
        height: unset;
        right: unset;
        width: 100%;
        top: 0;
        object-fit: cover;
    }

    .banenr__contnent {
        left: unset;
        width: 472px;
        height: 472px;
        top: -250px;
    }

    .banenr__icon_pc{
        display: none;
    }
    .banenr__icon_mobile{
        display: block;
        bottom: 32px;
        position: absolute;
    }
}

@media (max-width: 470px) {
.banenr__bg{
    top: 66px;
}
}