@import url(./assets/fonts/Inter/stylesheet.css);
@import url(./assets/fonts/Manrope/stylesheet.css);
@import url(./assets/fonts/Lobster/stylesheet.css);

body {
  margin: 0;
  font-family: 'Manrope', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  white-space: pre-line;
  font-style: normal;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

button {
  font-family: 'Inter', 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea {
  font-style: normal;
  color: var(--text-color);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
}

img {
  pointer-events: none;
  user-select: none;
}

:root {
  --text-color: #1E2A49;
  --text-on-contrast-color: #FFFFFF;
  --link-text-color: #2256DA;
  --background-color: #FFFFFF;
  --accent-color: #FFC700;

  --btn-inactive-color: #C4C9D7;
  --btn-hover-color: #FFD74B;
  --btn-active-color: #FFB800;
  --btn-on-contrast-hover-color: rgba(255, 255, 255, 0.80);
  --btn-on-contrast-active-color: rgba(255, 255, 255, 0.90);
  --btn-selected-color: #1E2A49;
  --control-btn-color: #77819A;

  --input-bg-color: #F8FAFF;
  --border-color: #E1E1F1;
  --icon-primary-color: #9EACCD;
  --popup-overlay-color: rgba(218, 226, 246, 0.78);
  --container-shadow-color: rgba(121, 131, 167, 0.10);

  --primary-shape-bg-color: #F1F8FF;
  --accent-shape-bg-color: #FFC731;
  --opacity-shape-bg-color: rgba(255, 255, 255, 0.3);

  --heading-shape-bg-color: #FF8A00;
  --heading-shape-secondary-bg-color: #FFA800;
  --logo-bg-color: #FFDC48;

  --bnr-bg-color: #FFBA00;

}