.on-home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
    position: relative;
    z-index: 1;
}

.on-home__bg-cirlce {
    position: absolute;
    z-index: 0;
}

.on-home__bg-cirlce_1 {
    left: -220px;
    top: calc(94px);
    width: 1224px;
    height: 631px;
}

.on-home__bg-cirlce_2 {
    width: 1224px;
    height: 1224px;
    top: 296px;
    right: -590px;
}

.on-home__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: calc(88px + 94px) 120px 100px;
    margin-top: -94px;
}

.on-home__cap {
    z-index: 1;
    width: 479px;
    height: 440px;
    left: 7px;
    top: 0;
    position: absolute;
}


.on-home__text-banner {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 auto;
    position: relative;
    z-index: 2;
}

.on-home__text-banner-title {
    color: var(--accent-color);

    font-family: 'Lobster';
    font-size: 110px;
    font-style: normal;
    font-weight: 400;
    line-height: 112px;
}

.on-home__text-banner-subtitle {
    color: var(--accent-color);
    margin-left: 191px;
    font-family: 'Lobster';
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 92px;
}

.on-home__lamp-icon {
    width: 192px;
    height: 192px;
    transform: rotate(15deg);
    position: absolute;
    z-index: 0;
    left: 168px;
    top: 350px;
}

.on-home__lamp-icon-stroke {
    stroke: var(--accent-color);
}

.on-home__accent-and-rethought {
    width: 100%;
    display: grid;
    grid-template-columns: 237px 1fr;
    grid-template-rows: max-content;
    column-gap: 83px;
    position: relative;
    z-index: 2;
    margin: 88px 0 0;
}

.on-home__rethought {
    color: var(--text-color);

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    max-width: 237px;
    margin: 66px 0 0;
    /* margin: 154px 0 0; */
    /* position: absolute;
    z-index: 1;
    top: 154px;
    left: 120px; */
}

.on-home__accent {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 20px;
}

.on-home__accent-card {
    background-color: var(--accent-color);
    border-radius: 50px;
    min-height: 200px;
    padding: 0 0 30px 30px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.on-home__accent-card-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 168px;
    border-radius: 50%;
    position: absolute;
    right: -14px;
    top: -24px;
    background-color: var(--opacity-shape-bg-color);
}

.on-home__accent-card-icon {
    width: 80px;
    height: 80px;
}

.on-home__accent-card-icon-stroke {
    stroke: var(--text-on-contrast-color);
}

.on-home__accent-card-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: var(--text-color);
    margin: 118px 0 0;
}


.on-home__slides {
    width: 100%;
}

@media (max-width: 1250px) {
    .on-home__container {
        padding: calc(88px + 94px) 20px 100px 40px;

    }
}

@media (max-width: 1100px) {
    .on-home__container {
        padding: 30px 16px 40px;
        margin-top: 0;
        align-items: center;
    }

    .on-home__cap {
        position: relative;
        top: unset;
        left: unset;
        width: 270px;
        height: 231px;
        z-index: 0;
    }

    .on-home__text-banner {
        margin: 0;
    }

    .on-home__text-banner-title {
        font-size: 80px;
        font-style: normal;
        font-weight: 400;
        line-height: 105%;
    }

    .on-home__text-banner-subtitle {
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 105%;
        margin-left: 43px;
    }

    .on-home__lamp-icon {
        width: 92px;
        height: 92px;
        top: 213px;
        left: unset;
        margin-left: 200px;
        z-index: 1;
    }

    .on-home__accent-and-rethought {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        grid-template-columns: unset;
        column-gap: unset;
        grid-template-rows: unset;
        margin: 0;
        gap: 20px;
    }

    .on-home__rethought {
        margin: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        max-width: 335px;
    }

    .on-home__bg-cirlce_1 {
        width: 903px;
        height: calc(903px / 2);
        top: 0;
        left: unset;
        right: -348px;
    }

    .on-home__bg-cirlce_2 {
        width: 903px;
        height: 903px;
        top: 306px;
        right: unset;
        left: -295px;
    }
}

@media (max-width: 800px) {

    .on-home__container {
        padding: 30px 0 40px;
    }

    .on-home__text-banner {
        padding: 0 20px;
    }

    .on-home__rethought {
        padding: 0 16px;

    }

    .on-home__cap {
        margin-right: 50px;
    }

    .on-home__lamp-icon {
        margin-left: 150px;
    }

    .on-home__text-banner-title {
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 105%;
    }

    .on-home__text-banner-subtitle {
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 105%;
        margin-left: 43px;
    }

    .on-home__bg-cirlce_1 {
        width: 563px;
        height: calc(563px / 2);
        top: 0;
        left: unset;
        right: -348px;
    }

    .on-home__bg-cirlce_2 {
        width: 482px;
        height: 482px;
        top: 306px;
        right: unset;
        left: -295px;
    }

    .on-home__accent-card {
        max-width: 260px !important;
        height: 160px !important;
        min-height: unset;
        border-radius: 40px;
        box-sizing: border-box;
    }

    .on-home__accent-card:first-of-type {
        margin-left: 16px;
    }

    .on-home__accent-card:last-of-type {
        margin-right: 16px;
    }

    .on-home__accent-card-icon-box {
        width: 140px;
        height: 140px;
        right: -11px;
        top: -11px;
    }

    .on-home__accent-card-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 82px 0 0;
    }
}