.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.footer__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    padding: 30px 120px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer__logo {
    width: 102px;
    height: 86px;
}

.footer__copyright{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}


@media (max-width: 880px) {
    .footer__container {
        width: 100%;
        max-width: 1440px;
        display: flex;
        position: relative;
        box-sizing: border-box;
        padding: 18px 16px;
        flex-direction: column;
        align-items: center;
gap: 24px;
    }
    
}